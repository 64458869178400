<template>
    <OModal ref="configUrlModal" @show="onModalShown">
        <div class="modal-dialog" style="max-width: 90%;">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("Element Url Builder") }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" style="height:65vh;">
                    <ORowContainer>
                        <OTabs id="elementsTabs" ref="elementsTabsRef">
                            <OTab :title="$t('Elements')" id="elements" v-if="showElements">
                                <OColContainer>
                                    <ODataGrid :dataObject="dsReportElementsUrlBuilder" hideGridMenu loadDataObject>
                                        <OColumn field="ArticleID" :headerName="$t('ArticleID')" width="300"/>
                                        <OColumn field="Article" :headerName="$t('Article')" width="300"/>
                                        <OColumn field="Tech" :headerName="$t('Tech')" hide width="50"/>
                                    </ODataGrid>
                                    <div class="ps-2" style="height:100%; width:50%; min-width:50%; overflow-y:auto">
                                        <div class="d-flex flex-row p-0">
                                            <span class="text-break"> {{ statusElementUrl }}</span>
                                            <a class="ms-auto" href="/o365setup?articleId=o365setup-statusreportingelements" target="_blank" rel="noreferrer noopener">
                                                <span>{{ $t("Elements Configuration") }}</span>
                                            </a>
                                        </div>
                                        <div v-for="item in elementsFilterInputs" :key="item.ID" class="d-flex flex-column">
                                            <div>
                                                <label :for="item.ID+'input'">{{ item.FieldName + (item.Description ? " ("+item.Description+")" : "") }}</label>
                                                <input :id="item.ID+'input'" type="text" class="form-control form-control-sm" v-model="item.Value" v-if="item.Type == 'text' || item.Type == 'string' || item.Type == 'number' || item.Type == null"/>
                                                <ODatePicker :id="item.ID+'input'" class="form-control form-control-sm w-100" v-model="item.Value" v-if="item.Type == 'date'" format="Short Date"></ODatePicker>
                                                <input :id="item.ID+'input'" type="checkbox" class="w-100 me-auto" v-model="item.Value" v-if="item.Type == 'checkbox'"/>
                                                <!-- lookups -->
                                                <DynamicElementLookupCT :id="item.ID+'input'" 
                                                                        :appID="dsReportElementsUrlBuilder.current.ArticleID" 
                                                                        :config="item"
                                                                        v-if="item.Type == 'lookup'"
                                                                        @callbackFunction="lookupCallback"/>
                                                <DynamicLookup :id="item.ID+'input'" 
                                                    :config="{Value:item.Value, ViewName:'aviw_Contract_Procurements', Fields:[{name:'ID', type:'number' },{name:'Procurement', type:'string', width:400 }], Multiselect:false, DisplayField:'Procurement'}" 
                                                    v-if="item.Type == 'procurementLookup'" 
                                                    @callbackFunction="sel=>{ item.Value = sel.ID}"
                                                />
                                                <OOrgUnitsLookup :id="item.ID+'input'" :value="item.Value" :bind="sel=>{ item.Value = sel.ID}" v-if="item.Type == 'orgUnitLookup'" class="form-select form-select-sm" textInput readonly/>
                                                <OPersonsLookup :id="item.ID+'input'" :value="item.Value" :bind="sel=>{ item.Value = sel.ID}" v-if="item.Type == 'personsLookup'" class="form-select form-select-sm" readonly/>
                                                <OOrgUnitsLookup :id="item.ID+'input'" :value="item.Value" :bind="sel=>{ item.Value = sel.ID}" v-if="item.Type == 'companyLookup'" class="form-select form-select-sm" textInput readonly whereClause="Company_ID IS NOT NULL"/>
                                                <OScopeProcessesLookup :id="item.ID+'input'" :value="item.Value" :bind="sel=>{ item.Value = sel.ID}" v-if="item.Type == 'processLookup'" class="form-select form-select-sm" textInput readonly/>

                                                <DynamicLookup :id="item.ID+'input'" 
                                                                :config="{Value:item.Value, ViewName:'aviw_Contract_Procurements', Fields:[{name:'ID', type:'number' },{name:'Procurement', type:'string', width:400 }], Multiselect:false, DisplayField:'Procurement'}" 
                                                                v-if="item.Type == 'procurementLookup'" 
                                                                @callbackFunction="sel=>{ item.Value = sel.ID}"
                                                />

                                                <OWBSLookup :value="item.Value" :bind="sel=>{ item.Value = sel.ID; }" v-if="item.Type == 'wbsLookup'" class="form-select form-select-sm" textInput readonly/>
                                            </div>
                                        </div>
                                        <iframe :src="statusElementUrl" class="w-100 h-100"></iframe>
                                    </div>
                                </OColContainer>
                            </OTab>
                            <OTab :title="$t('Charts')" id="charts" v-if="showCharts">
                                <OColContainer>
                                    <ODataGrid :dataObject="dsChartsUrlBuilder" hideGridMenu loadDataObject>
                                        <OColumn field="ChartLayout" :headerName="$t('Chart Layout')" width="300"/>
                                        <OColumn field="ChartLayoutTitle" :headerName="$t('Chart Layout Title')" width="300"/>
                                    </ODataGrid>
                                    <iframe id="chart-iframe" :src="'/chart-preview?Chart='+dsChartsUrlBuilder.current.ChartLayout" style="height: 100%;width:50%; min-width:50%;"></iframe>
                                </OColContainer>
                            </OTab>
                            <OTab :title="$t('Reports')" id="reports" v-if="showReports">
                                <OColContainer>
                                    <ODataGrid :dataObject="dsMyReportsUrlBuilder" hideGridMenu loadDataObject>
                                        <OColumn field="ReportID" :headerName="$t('Report ID')" width="300"/>
                                        <OColumn field="Title" :headerName="$t('Title')" width="300"/>
                                    </ODataGrid>
                                    <div class="ps-2 d-flex flex-column" style="width:50%; min-width:50%; height:100%; overflow-y:auto; overflow-x:hidden;">
                                        <div class="d-flex flex-column">
                                            <div class="d-flex flex-row w-100">
                                                <div>
                                                    <label for="customFilterSelect">Select a filter for the report</label> 
                                                    <select class="form-select form-select-sm" name="Default" id="customFilterSelect" v-model="reportSelectedFilter">
                                                        <option value='Default' selected>Default</option>
                                                        <option v-for="filter in dsMyReportsFilterTemplates.data" :key="filter.Name" :value="filter">{{ filter.Name }}</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <button type="button" class="btn btn-sm btn-outline-primary ms-2 mt-4" @click="loadReport = true">Preview Report</button>
                                                </div>
                                                <a class="ms-auto" href='/myreports' target='_blank' rel='noreferrer noopener'>
                                                    <span>Reports Configuration <i class="far fa-external-link"></i></span>
                                                </a>
                                            </div>
                                            <div class="" style="line-height:3;">
                                                <div v-for="item in reportsFilterInputs">
                                                    <label :for="item.ID">{{ item.Text }}</label>
                                                    <ODatePicker :id="item.ID" class="form-control form-control-sm w-100" v-model="item.Value" v-if="item.Type == 'DatePicker'" format="Short Date"></ODatePicker>
                                                    <input :id="item.ID" type="checkbox" class="w-100" v-model="item.Value" v-else-if="item.Type == 'Radio'"/>
                                                    <OOrgUnitsLookup :id="item.ID" :value="item.Value" :bind="sel=>{ item.Value = sel.ID}" v-else-if="item.Type == 'OrgUnit_Lookup'" class="form-select form-select-sm" textInput readonly/>

                                                    <DynamicLookup :id="item.ID"
                                                                    :config="item"
                                                                    v-else-if="isCustomReportLookup(item.Type)"
                                                                    @callbackFunction="reportLookupCallback"/>

                                                    <input :id="item.ID" type="text" class="form-control form-control-sm" v-model="item.Value" v-else/>
                                                </div>
                                            </div>
                                        </div>
                                        <span class="text-break"> {{ statusElementUrl }}</span>
                                        <div v-if="loadReport" class="">
                                            <div v-show="isReportLoading">
                                                Loading preview
                                                <div class="spinner-border spinner-border-sm" role="status">
                                                    <span class="visually-hidden"></span>
                                                </div>
                                            </div>
                                            <img v-show="!isReportLoading" 
                                                    :src="statusElementUrl" 
                                                    class="border" 
                                                    style="min-width:200px;min-height:200px;overflow-y:auto;" 
                                                    :onload="onReportLoaded()"
                                                    />
                                        </div>
                                    </div>
                                </OColContainer>
                            </OTab>
                        </OTabs>
                    </ORowContainer>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="setUrl">{{ $t("Set") }}</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("Cancel") }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
    import { ref, onMounted, computed, watch } from "vue";
    import { getOrCreateDataObject } from "o365-dataobject";
    import { OModal, ODatePicker, OIframe } from "o365-ui-components";
    import { useDataObjectEventListener } from "o365-vue-utils";
    import { utils } from 'o365-utils';
    import { OOrgUnitsLookup, OPersonsLookup, OWBSLookup, OScopeProcessesLookup } from 'o365-system-lookups';

    import DynamicElementLookupCT from "status.vue.components.DynamicElementLookupCT.vue";
    import DynamicLookup from "status.vue.components.DynamicLookup.vue";

    const props = defineProps({
        dataObject: {
            Type: Object,
            Required: true
        },
        showElements: {
            Type: Boolean,
            Default: false
        },
        showCharts: {
            Type: Boolean,
            Default: false
        },
        showReports: {
            Type: Boolean,
            Default: false
        }
    });

    const elementsTabsRef = ref(null);
    const configUrlModal = ref(null);

    const elementsFilterInputs = ref([]);

    const reportsFilterInputs = ref([]);

    const loadReport = ref(false);
    const isReportLoading = ref(true);
    const reportSelectedFilter = ref("Default");

    const statusElementUrl = ref(null);

    // Elements
    const dsReportElementsUrlBuilder = getOrCreateDataObject({
        id: "dsReportElementsUrlBuilder",
        viewName: "aviw_Status_Elements",
        maxRecords: 25,
        whereClause: "",
        selectFirstRowOnLoad: true,
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "PrimKey", type: "string" },
            { name: "ArticleID", type: "string", sortOrder: 1, sortDirection: "asc" },
            { name: "Article", type: "string" },
            { name: "Tech", type: "string" },
            { name: "QueryString", type: "string" },
            { name: "Config", type: "string" }
        ]
    });
    // Charts
    const dsChartsUrlBuilder = getOrCreateDataObject({
        id: "dsChartsUrlBuilder",
        viewName: "aviw_Status_Charts",
        maxRecords: 25,
        whereClause: "",
        selectFirstRowOnLoad: true,
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "ChartLayout", type: "string", sortOrder: 1, sortDirection: "asc" },
            { name: "ChartLayoutTitle", type: "string" },
        ]
    });
    // Reports
    const dsMyReportsUrlBuilder = getOrCreateDataObject({
        id: "dsMyReportsUrlBuilder",
        viewName: "sviw_Reporting_MyReports",
        maxRecords: 25,
        whereClause: "",
        selectFirstRowOnLoad: true,
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "PrimKey", type: "string" },
            { name: "ReportID", type: "string", sortOrder: 1, sortDirection: "asc" },
            { name: "Title", type: "string" },
            { name: "ViewName", type: "string" },
            { name: "InitialFilter", type: "string" },
            { name: "FilterTemplate", type: "string" }
        ]
    });

    const dsMyReportsFilterTemplates = getOrCreateDataObject({
        id: "dsMyReportsFilterTemplates",
        viewName: "stbv_Database_FilterTemplates",
        maxRecords: 25,
        whereClause: "",
        selectFirstRowOnLoad: true,
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "Name", type: "string" },
            { name: "ViewName", type: "string" },
            { name: "FilterTemplate", type: "string" }
        ],
        masterDataObject_ID: "dsMyReportsUrlBuilder",
        masterDetailDefinition: [
            {
                detailField: "ViewName",
                masterField: "ViewName",
                operator: "equals"
            }
        ],
    });

    const dsMyReportsFilterConfig = getOrCreateDataObject({
        id: "dsMyReportsFilterConfig",
        viewName: "stbv_Database_InputEditors",
        maxRecords: 25,
        whereClause: "",
        selectFirstRowOnLoad: true,
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "ID", type: "number" },
            { name: "Name", type: "string" },
            { name: "Config", type: "string" }
        ]
    });

    const dsSystemInputEditors = getOrCreateDataObject({
        id: "dsSystemInputEditors",
        viewName: "sviw_Database_InputEditors",
        maxRecords: 25,
        whereClause: "",
        selectFirstRowOnLoad: true,
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "Name", type: "number" },
            { name: "Config", type: "string" },
            { name: "JsonConfig", type: "string" }
        ]
    });

    const createUrl = function(){
        const url = {
            setForElement(){
                var itemArray = [];
                elementsFilterInputs.value.forEach(function(i){
                    if (String(i.Value) !== "" && !!i.Value) {
                        if(i.Type == 'date'){
                            itemArray.push(i.FieldName + "=" + utils.formatDate(i.Value, "Short Date"));
                        }else{
                            itemArray.push(i.FieldName + "=" + i.Value);
                        }
                    }
                });
                statusElementUrl.value = "/"+dsReportElementsUrlBuilder.current.ArticleID + (itemArray ? "?":"") + itemArray.join('&');
            },
            setForChart(){
                let chartUrl = document.getElementById("chart-iframe").contentWindow.document.querySelector("#preview-chart-img").src;
                statusElementUrl.value = chartUrl.substring(chartUrl.indexOf("api")-1);
            },
            setForReport(){
                var itemArray = [];
                reportsFilterInputs.value.forEach(function(i){
                    if (String(i.Value) !== "" && !!i.Value) {
                        if(i.Type == 'DatePicker'){
                            itemArray.push(i.Text + (i.HasApostrophe ? "'" : "") + utils.formatDate(i.Value, "Short Date") + (i.HasApostrophe ? "'" : ""));
                        }else{
                            itemArray.push(i.Text + (i.HasApostrophe ? "'" : "") + i.Value + (i.HasApostrophe ? "'" : ""));
                        }
                    }
                });
                statusElementUrl.value = "/file/view/reporting/" + dsMyReportsUrlBuilder.current.ReportID + "/" + dsMyReportsUrlBuilder.current.ReportID + ".png?Filter=" + itemArray.join(' AND ');
            }
        }
        return url;
    }

    const urlFunctions = createUrl();

    function onModalShown(){
        if(elementsTabsRef.value.getActiveTab()){
            return;
        }
        if(props.showElements){
            elementsTabsRef.value.setActiveTab("elements");
        }else if(props.showCharts){
            elementsTabsRef.value.setActiveTab("charts");
        }else if(props.showReports){
            elementsTabsRef.value.setActiveTab("reports");
        }
    }

    function getElementsInputs(filter){
        let fields = [];
        let objs = [];
        
        if(filter){
            fields = filter.slice(1).split("&");
            fields.forEach(function(i){
                let obj =  {ID: i.substring(0, i.indexOf("=")),
                            FieldName: i.substring(0, i.indexOf("=")),
                            Type: i.indexOf(":") == -1 ? i.substring(i.indexOf("=")+1, i.length) : i.substring(i.indexOf("=")+1, i.indexOf(":")),
                            Description: i.indexOf(":") == -1 ? "" : i.substring(i.indexOf(":")+1, i.indexOf("|") == -1 ? i.length : i.indexOf("|")),
                            DataSource: i.indexOf("|") == -1 ? "" : i.substring(i.indexOf("|")+1, i.indexOf(";")),
                            DsFields: i.indexOf(";") == -1 ? "" : i.substring(i.indexOf(";")+1, i.indexOf("-") == -1 ? i.length : i.indexOf("-")),
                            DisplayField: i.indexOf("-") == -1 ? "" : i.substring(i.indexOf("-")+1),
                            Value: "",
                            AdditionalData: 
                            {
                                Value: '',
                                ViewName: '',
                                Fields: [{ name: "", type: "" }],
                                Multiselect: false,
                                DisplayField: ""
                            }
                        };

                if(props.dataObject.current.Url){
                    var url = new URL("https://www.omega365.com" + props.dataObject.current.Url);
                    obj.Value = url.searchParams.get(obj.FieldName);
                }
                objs.push(obj);
            });
        }
        return objs;
    }

    function getElementsInputsFromConfig(config){
        let objs = [];

        if(config){
            Object.keys(config).forEach(function(key){
                let obj = 
                {
                    ID: key,
                    FieldName: key,
                    Type: config[key].type,
                    Description: "",
                    DataSource: "",
                    DsFields: "",
                    DisplayField: key,
                    Value: config[key].default
                };

                if(props.dataObject.current.Url){
                    var url = new URL("https://www.omega365.com" + props.dataObject.current.Url);
                    obj.Value = url.searchParams.get(obj.FieldName);
                }
                objs.push(obj);
            });
        }
        return objs;
    }

    function getReportsInputs(row){
        let tempList;

        reportsFilterInputs.value = [];
        if(reportSelectedFilter.value === "Default"){
            tempList = dsMyReportsUrlBuilder.current.FilterTemplate?.split(" AND ");
        }else{
            tempList = row.FilterTemplate.split(" AND ");
        }

        tempList?.forEach((i)=>{
            let parseObj = i.split("{{");
            parseObj[1] = parseObj[1].replace("}}", "").replace("'", "");
            let lookupItem = dsMyReportsFilterConfig.data.find(obj => obj.Name == parseObj[1]);
            let config = !lookupItem?.Config ? {} : JSON.parse(lookupItem.Config);
            reportsFilterInputs.value.push(
                {
                    ID: parseObj[1]+reportsFilterInputs.value.length, 
                    Text: parseObj[0].slice(0, parseObj[0].length - 1).replaceAll(" ", ""), 
                    Type: parseObj[1], 
                    Value: "", 
                    HasApostrophe: parseObj[0].includes("'"),
                    ViewName: config?.ViewName,
                    Fields: [],
                    Multiselect: !!config?.Multiselect,
                    DisplayField: config?.DisplayMember
                }
            );
            config?.Columns?.split(",").forEach((i)=>{
                let t = i.split(":");
                reportsFilterInputs.value[reportsFilterInputs.value.length-1].Fields.push({ name: t[0], type: t[2] });
            });
        });
    }

    function setUrl(){
        if(elementsTabsRef.value.getActiveTab() == "charts"){
            urlFunctions.setForChart();
        }
        props.dataObject.current.Url = statusElementUrl.value;
        props.dataObject.save();
        configUrlModal.value.hide();
    }

    function lookupCallback(row, item){
        let i = elementsFilterInputs.value.find(obj => obj.ID == item.ID);
        i.Value = row[item.DisplayField];
    }

    function reportLookupCallback(row, item){
        let i = reportsFilterInputs.value.find(obj => obj.ID == item.ID);
        i.Value = row[item.DisplayField];
    }

    function onReportLoaded(){
        isReportLoading.value = false;
    }



    function isCustomReportLookup(type){
        if(dsMyReportsFilterConfig.data.find(obj => obj.Name == type)){
            return true;
        }else{
            return false;
        }
    }



    watch(reportSelectedFilter, () => {
        getReportsInputs(reportSelectedFilter.value);
    });

    watch(
        elementsFilterInputs, 
        () => {
            urlFunctions.setForElement();
        },
        { deep: true }
    );

    watch(
        reportsFilterInputs, 
        () => {
            urlFunctions.setForReport();
        },
        { deep: true }
    );

    onMounted(()=>{
        useDataObjectEventListener(dsReportElementsUrlBuilder, 'CurrentIndexChanged', () => {
            // console.log(JSON.parse(dsReportElementsUrlBuilder.current.Config));
            // console.log(JSON.parse(dsReportElementsUrlBuilder.current.Config).queryParameters);
            if(dsReportElementsUrlBuilder.current.Config && JSON.parse(dsReportElementsUrlBuilder.current.Config).queryParameters){
                elementsFilterInputs.value = getElementsInputsFromConfig(JSON.parse(dsReportElementsUrlBuilder.current.Config).queryParameters);
            }else{
                elementsFilterInputs.value = getElementsInputs(dsReportElementsUrlBuilder.current.QueryString);
            }
        });

        useDataObjectEventListener(dsMyReportsUrlBuilder, 'CurrentIndexChanged', () => {
            loadReport.value = false;
            isReportLoading.value = true;
            reportSelectedFilter.value = "Default";
        });

        useDataObjectEventListener(dsMyReportsFilterTemplates, 'CurrentIndexChanged', () => {
            getReportsInputs();
        });

        useDataObjectEventListener(dsMyReportsFilterTemplates, 'DataLoaded', () => {
            getReportsInputs();
        });

        dsMyReportsFilterConfig.load();
        dsSystemInputEditors.load();
    });
</script>